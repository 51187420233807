<template>
  <div class="_main_wrapper relative flex">
    <sidebar label="Create programmatic"
             :feature="'Programmatic'"
             :categories="categories"
             :display-mode="displayMode"
             :items="programmatics"
             @toggleCreateModal="toggleCreateModal"
             @toggleDisplay="toggleDisplay"
             :canCreate="canCreate(project.cans)" />

    <div class="i-right-panel">
      <div class="ws-heading-box relative flex flex-col pb-8">
        <workspace-menu />
        <h2 class="fs-14 fw-700 font-poppins text-secondary-one absolute page-heading-title">Programmatic Management</h2>
        <div class="ws-action-box flex items-center justify-between ws-select-dropdown">
          <div class="flex scenario-action-box">
            <div class="flex items-center gap-3">
              <div class="relative i-border-1 border-secondary-two rounded ws-search-width">
                <input
                    type="text"
                    class="focus:outline-none py-2 font-poppins ws-search-box bg-transparent text-secondary-one placeholder-secondary-two"
                    :placeholder="searchField.placeholder"
                    v-model="searchField.text"
                    @click="searchField.placeholder = ''"
                    v-click-outside="outsideSearchInput"
                />
                <div class="mr-2 flex items-center justify-between absolute inset-y-0 right-0 pointer-events-none pr-1">
                  <img
                      class="w-3 h-3"
                      src="/images/icons/search_icon.svg"
                  />
                </div>
              </div>
              <select v-model="scenario_id" class="bg-transparent text-secondary-two fs-14 i-border-1 border-secondary-two py-2 rounded px-4" placeholder="Select scenario">
                <option value="null">Select Scenario</option>
                <option v-for="(scenario, sIndex) in scenarios" :key="sIndex" :value="scenario.id">{{ scenario.title }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="scenario-content-box pl-10 mr-8 mt-8 overflow-y-auto ws-scrollbar">

        <div class="roadmapCards grid grid-cols-3 gap-y-8">
          <DataCard
            v-for="(item, index) in getDisplayData"
            :key="index+Math.floor(Math.random()*100)"
            :routeObj="{ name: 'roadmap.show', params: { id: $route.params.id, roadmap_id: item.id }}"
            :title="item.title"
            img="/img/default_dashboard.svg"
            subTitle="Active Roadmap for : "
            :category="'Category: ' + categories[item.category_index]"
            :afterSwitch="item.scenario && item.scenario.title ? item.scenario.title : ''"
            :data="[
              {Created: $lodash.format(item.created_at, 'dd/MM/yyyy')},
              {Last_Edited: $lodash.format(item.updated_at, 'dd/MM/yyyy')},
            ]"
            :editCallBack="() => { toggleEditModal(true, item) }"
            :duplicateCallBack="() => { duplicateProgrammatic(item) }"
            :deleteCallBack="() => { deleteProgrammatic(item.id, index) }"
            :defaultSetCallBack="() => { setDefault(item) }"
            :color="(item.settings && item.settings.color) ? item.settings.color : defaultColor"
            :isDefault="item.is_default"
            :image="item?.file?.file_path"
          />
        </div>
      </div>
    </div>

    <roadmap-create-modal :dModal="cModal"
                          :categories="categories"
                          @toggleCreateModal="toggleCreateModal" />
    <roadmap-edit-modal :dModal="eModal"
                        :categories="categories"
                        :roadmap="editableData"
                        :ref="'roadmap_edit'"
                        @toggleEditModal="toggleEditModal" />
  </div>
</template>

<script setup>
  import DataCard from '@/elements/DataCard.vue'
  import usePermission from '@/composable/usePermission.js'
  const { canCreate } = usePermission()
</script>


<script>
import axios from 'axios'
import Sidebar from "../../../components/roadmap/management/Sidebar";
import {mapGetters} from "vuex";
import RoadmapCreateModal from "../../../components/roadmap/management/RoadmapCreateModal";
import RoadmapEditModal from "../../../components/roadmap/management/RoadmapEditModal";

import { categories } from '@/mock/category'
export default {
  name: "Index",
  components: {RoadmapEditModal, RoadmapCreateModal, Sidebar},
  created() {
    document.title = "Programmatic Management";
    const self = this;
    self.$store.dispatch("workspace/projectSelection", self.$route.params.id);
    this.$store.dispatch("project/getSolidObject", this.$route.params.id);
    self.$Progress.finish();
    self.$store.dispatch("roadmap/getProgrammatics", {
      project_id: self.$route.params.id
    }).then(response => {
      self.$Progress.finish();
    });

    this.$store.dispatch("programmatic/loadScenarios", this.$route.params.id);
  },
  data() {
    return {
      cModal: false,
      eModal: false,
      displayMode: 'all',
      searchField: {
        placeholder: 'Search',
        text: ''
      },
      hoveredIndex: '',
      categories,
      editableData: null,
      scenario_id: null,
      defaultColor: '#2b80ad'
    }
  },
  computed: {
    ...mapGetters({
      programmatics: "roadmap/programmatics",
      scenarios: 'programmatic/scenarios',
      project: 'project/project',
    }),

    getDisplayData() {
      let programmatics = [];
      if (this.displayMode === 'all') {
        programmatics = this.programmatics;
      } else if (this.displayMode === 'deleted') {
        programmatics = this.programmatics.filter(item => item.deleted_at);
      } else {
        programmatics = this.programmatics.filter(item => item.category_index === this.displayMode);
      }

      if (this.searchField.text) {
        return programmatics.filter((item) => {
          return this.searchField.text
              .toLowerCase()
              .split(" ")
              .every((v) => item.title.toLowerCase().includes(v));
        });
      }

      if(this.scenario_id){
        programmatics = programmatics.filter(data => data.scenario_id === this.scenario_id)
      }

      return programmatics;
    }
  },
  methods: {

    setDefault(item)
    {
      const self = this;
      const defaultData = this.getDisplayData.filter(item=>item.is_default)
      defaultData.forEach(element => {
        const data = {
          id: element.id,
          project_id: element.project_id,
          category_index: element.category_index,
          title: element.title,
          is_default: 0,
        }
        axios.post(`roadmaps/${element.id}/update`, data).then(response => {
          self.$store.dispatch("project/getSolidObject", self.$route.params.id)
        })
      })

      const data = {
        id: item.id,
        project_id: item.project_id,
        category_index: item.category_index,
        title: item.title,
        is_default: 1
      }

      axios.post(`roadmaps/${item.id}/update`, data).then(response => {
        self.$store.dispatch("project/getSolidObject", self.$route.params.id)
      })
    },


    outsideSearchInput() {
      this.searchField.placeholder = 'Search';
    },
    toggleCreateModal(value) {
      this.cModal = value;
      this.editableData = null;
    },
    toggleDisplay(mode) {
      this.displayMode = mode;
      this.editableData = null;
    },
    toggleEditModal(value, item) {
      this.eModal = value;
      this.editableData = item;
      this.$refs.roadmap_edit.setEditableData(item);
    },
    duplicateProgrammatic(item) {
      this.$Progress.start();
      this.$store.dispatch("roadmap/duplicateProgrammatic", item).then(response => {
        this.$Progress.finish();
      });
    },
    deleteProgrammatic(id, index) {
      let payload = {
        id: id,
        index: index,
        project_id: this.$route.params.id
      };
      this.$Progress.start();
      this.$store.dispatch("roadmap/deleteProgrammatic", payload).then(response => {
        this.$Progress.finish();
      })
    },
    mouseOver(event, index) {
      this.hoveredIndex = index;
    },
    outsideOption() {
      this.hoveredIndex = '';
    },
    getContentsLength(item) {
      if(item.scenario && item.scenario.collections) {
        return item.scenario.collections.length;
      }
      return 0;
    }
  }
}
</script>

<style scoped>

/*   @media all and (max-width: 1850px) and (min-width: 1300px){
    .roadmapCards{
      grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
      gap: 32px;
    }
    .roadmapCards>.scenario-item-box{
      max-width: 600px;
      width: 100%;
    }
  } */
</style>
